import get from "lodash/get"

const fetchWindowObject = async (name) => {
  return new Promise((resolve) => {
    let object = get(window, name)

    if (object) return resolve(object)

    const interval = setInterval(() => {
      object = get(window, name)

      if (object) {
        clearInterval(interval)
        resolve(object)
      }
    }, 300)
  })
}

export default fetchWindowObject
